<template>
  <NewsListView></NewsListView>
</template>

<script>
import NewsListView from "@/views/news/components/NewsListView";
export default {
  name: "NewsView",
  components: {NewsListView}
}
</script>

<style scoped>
</style>