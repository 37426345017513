<template>
  <section class="pp-sec-pad">
    <div class="cp-c-pad container" style="margin: 50px auto;">
      <div id="doing" align="center"></div>
      <ul id="t_body" class="news">
        <li class="list list-right-img" :key="news.id" v-for="news in newsList">
          <div class="left">
            <div class="new">
              <p class="title list-ellipsis-1">
                <router-link :to="'/information/detail?id=' + news.id" class="">{{ news.title }}</router-link>
              </p>
              <p class="content">
                {{ news.subtitle }}
              </p>
            </div>
            <span class="time">{{ news.timestamp }}</span></div>
          <div class="right">
            <router-link :to="'/information/detail?id=' + news.id" class="">
              <img :src="news.imageURL">
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
  import { mapState, useStore } from 'vuex'
  import news from "@/data/news";
  export default {
    name: "NewsListView",
    data() {
      return {
        newsList: []
      }
    },
    computed: {
      ...mapState({
        language: state => state.app.language
      })
    },
    watch: {
      language: function(newValue) {
        this.newsList = news[newValue]
      }
    },
    created() {
      this.store = useStore()
      this.newsList = news[this.store.state.app.language]
    },
    methods: {
      onSwitchLanguage(language) {
        this.currentLanguage = language
        this.store.dispatch('app/switchLanguage', language.value)
        this.$i18n.locale = language.value
        this.isShowLanguageSwitch = false
      }
    }
  }
</script>

<style scoped>
.news {
  background: #fff;
  margin: 0;
  padding: 0
}

.news .list {
  display: flex;
  display: -webkit-flex;
  padding: 10px 5px 5px 0;
  margin: 0 15px;
  border-bottom: 1px solid #ddd
}

.news .list:last-child {
  border: 0
}

.list-no-img {
  flex-direction: column
}

.list-no-img .top .title {
  font-weight: 700;
  height: 30%;
  font-size: 14px
}

.list-no-img .top .content {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 20px;
  font-size: 14px;
  color: #666;
  margin: 5px 0
}

.list-no-img .info {
  font-size: 10px;
  color: #bbb;
  display: flex
}

.list-no-img .info .info-r-5 {
  margin-right: 5px
}

.list-no-img .info .list-ellipsis-1 {
  max-width: 40%
}

.list-right-img .left {
  width: 60%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0 solid #000
}

.list-right-img .left .title {
  font-weight: 700;
  height: 30%;
  font-size: 14px
}

.list-right-img .left .content {
  font-size: 12px;
  color: #888;
  margin: 5px 0;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 16px
}

.list-right-img .left .time {
  font-size: 10px;
  color: #bbb
}

.list-right-img .right {
  width: 40%;
  margin-left: 10px
}

.list-right-img .right img {
  width: 140px;
  height: 85px
}

.list-ellipsis-1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}
</style>